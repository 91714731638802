<template>
  <div style="height: 100%; background-color: #f7f7f7">
    <div class="mine">
      <div class="line box">
        <div class="box_view" @click="goDetail">
          <img style="width:44px;height: 44px;border-radius: 44px;"
            :src="doctor.im ? doctor.im : require('../assets/image/doctor.png')" alt="" />
          <div class="box_mine">
            <div>
              <span class="name">{{ doctor.n }}</span>
              <span class="option" v-if="doctor.jt >= 0">{{
                jobTitle[doctor.jt].label
              }}</span>
              <span class="option2">{{ doctor.sn }}</span>
            </div>
            <p class="type">
              <span>{{
                doctor.hv && doctor.hv[0].hn ? doctor.hv[0].hn : " "
              }}</span>
              <i></i>
              <span>{{
                doctor.hv && doctor.hv[0].sn ? doctor.hv[0].sn : " "
              }}</span>
            </p>
          </div>
          <van-icon style="margin-left: auto" name="arrow" />
        </div>
      </div>
      <div class="classify">
        <div class="classify_view right_line">
          <span>本月检测订单量</span>
          <p>{{ order.md }}</p>
        </div>
        <div class="classify_view right_line">
          <span>总检测订单量</span>
          <p>{{ order.cd }}</p>
        </div>
        <div class="classify_view">
          <span>总销售额（元）</span>
          <p>
            {{ leftA }}<span>{{ rightA }}</span>
          </p>
        </div>
        <div class="classify_view right_line">
          <span>本月咨询单量</span>
          <p>{{ consults.md }}</p>
        </div>
        <div class="classify_view right_line">
          <span>总咨询单量</span>
          <p>{{ consults.cd }}</p>
        </div>
        <div class="classify_view">
          <span>总销售额（元）</span>
          <p>
            {{ leftB }}<span>{{ rightB }}</span>
          </p>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" round closeable :style="{ 'max-height': '70%' }">
      <div class="mine">
        <div class="line box">
          <div class="box_view box_bottom">
            <img style="width:44px;height: 44px;border-radius: 44px;"
              :src="doctor.im ? doctor.im : require('../assets/image/doctor.png')" alt="" />
            <div class="box_mine">
              <div>
                <span class="name">{{ doctor.n }}</span>
                <span class="option" v-if="doctor.jt">{{
                  jobTitle[doctor.jt].label
                }}</span>
              </div>
              <p class="type">{{ doctor.fn }} - {{ doctor.sn }}</p>
            </div>
            <!-- <van-icon style="margin-left: auto" name="arrow" /> -->
          </div>
          <div class="address">
            <p class="title">执业地点</p>
            <template v-if="doctor.hv && doctor.hv.length > 0">
              <div class="cnt" v-for="item in doctor.hv" :key="item.i">{{ item.hn }}<span>|</span>{{ item.sn }}</div>
            </template>
            <div v-else>
              - -
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";
import { jobTitle } from "@/util/options";
export default {
  name: "month",
  data() {
    return {
      dataInfo: {
        price: "91061927.00",
      },
      doctor: {},
      order: {},
      consults: {},
      leftA: '',
      rightA: '',
      leftB: '',
      rightB: '',
      show: false,
      jobTitle,
    };
  },
  mounted() {
    this.getDoctorInfo();
    this.getInfoCensus();
    this.getConsults()
  },
  methods: {
    goDetail() {
      this.show = true;
    },
    getDoctorInfo() {
      request.getDoctorInfo(this.$route.query.i).then((res) => {
        this.doctor = res;

      });
    },
    getInfoCensus() {
      request.getInfoCensus(this.$route.query.i).then((res) => {
        this.order = res;
        let cm = res.cm.toFixed(2)
        let leftA = cm.substring(
          0,
          cm.indexOf(".")
        );
        let rightA = cm.substring(
          cm.indexOf("."),
          cm.length
        );
        this.leftA = leftA;
        this.rightA = rightA;
      });
    },
    getConsults() {
      request.getConsults(this.$route.query.i).then((res) => {
        this.consults = res;
        let cm = res.cm.toFixed(2)
        let leftA = cm.substring(
          0,
          cm.indexOf(".")
        );
        let rightA = cm.substring(
          cm.indexOf("."),
          cm.length
        );
        this.leftB = leftA;
        this.rightB = rightA;
      });
    },
  },
  created() {
    ChangePageTitle("医生详情");
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f7f7f7;
}

.van-search__content {
  border-radius: 50px;
  border: 1px solid #e6e6e6;
  background-color: unset;
}

.time_select {
  background: #fff;
  padding: 7px 16px;
  color: #666666;
  font-size: 14px;
}

.mine {
  height: 100%;

  .line {
    margin-bottom: 12px;
  }

  .flex {
    display: flex;

    .van-cell {
      padding-top: 20px;
      padding-bottom: 16px;
    }
  }

  .left_cell {
    font-size: 28px;
    color: #1b88ff;

    >.van-cell__label {
      margin-top: 10px;
    }
  }

  .box {
    background: #fff;
    padding: 0 16px 0 16px;

    >p {
      font-size: 14px;
      padding-bottom: 8px;
    }

    .box_view {
      display: flex;
      align-items: center;
      padding: 15px 0;

      >img {
        height: 44px;
        width: 44px;
        margin-right: 8px;
      }

      .box_mine {
        >div {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
          }
        }

        .name {
          font-size: 16px;
          margin-right: 12px;
          overflow: hidden;
          /*内容超出后隐藏*/
          text-overflow: ellipsis;
          /* 超出内容显示为省略号 */
          white-space: nowrap;
          /* 文本不进行换行 */
          max-width: 85px;
        }

        .option {
          font-size: 12px;
          color: #1b88ff;
        }

        .option2 {
          margin-left: 12px;
          font-size: 12px;
          color: #999;
        }

        .type {
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 12px;
          margin-top: 5px;

          >i {
            width: 2px;
            height: 11px;
            background: #e6e6e6;
            border-radius: 1px;
            display: inline-block;
            margin: 0 8px;
          }

          >span {
            display: inline-block;
            overflow: hidden;
            /*内容超出后隐藏*/
            text-overflow: ellipsis;
            /* 超出内容显示为省略号 */
            white-space: nowrap;
            /* 文本不进行换行 */
            max-width: 105px;
          }
        }
      }
    }

    .box_bottom {
      margin-top: 39px;

      .box_mine {
        .name {
          max-width: none;
          white-space: normal;
        }

        .option {
          font-size: 20px;
          transform: scale(0.5);
          transform-origin: 0;
          background: #d6eaff;
          border-radius: 30px;
          color: #1987fe;
          padding: 8px 16px;
        }
      }
    }
  }

  .classify {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;

    .classify_view {
      width: 33.3%;
      text-align: center;
      padding: 20px 0;
      position: relative;

      >span {
        font-size: 12px;
        color: #999;
      }

      >p {
        margin-top: 15px;
        color: #1b88ff;
        font-size: 18px;

        >span {
          font-size: 12px;
        }
      }
    }

    .right_line {
      &::after {
        content: "";
        height: 30px;
        width: 1px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #E6E6E6;
      }
    }
  }
}

.address {
  margin-left: 52px;
  padding-bottom: 45px;

  .title {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .cnt {
    font-size: 14px;
    color: #999999;
    margin-bottom: 12px;

    span {
      margin: 0 8px;
    }
  }
}

.none_data {
  text-align: center;
  padding-top: 50px;
}

.van-icon {
  color: #999;
}
</style>
