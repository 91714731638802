<template>
  <div class="home">
    <div>
      <img class="img" :src="imgUrl" alt="" />
      <p class="tips">提示：长按可保存海报到手机，图片可自行分享好友、朋友圈</p>
    </div>
    <van-tabbar route>
      <van-tabbar-item replace to="/home">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-shouy' : '#icon-shouy-dele'"></use>
          </svg>
        </template>
        邀请
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-wd' : '#icon-wd-dele'"></use>
          </svg>
        </template>
        数据
      </van-tabbar-item>

    </van-tabbar>
  </div>
</template>

<script>
import request from "../request/api";
import { ChangePageTitle } from "@/util/title";
import config from '@/util/config'
import { Dialog } from 'vant';
const NOTAUTH = '0'  //未认证
export default {
  name: "home",
  data() {
    return {
      imgUrl: '',
    };
  },
  mounted() {
    request.getPosterImg().then((res) => {
      this.imgUrl = config + res.p
    });
    this.getAuthStatus()
  },
  // 设置浏览器标题
  created() {
    ChangePageTitle("邀请");
  },
  methods: {
    getAuthStatus() {
      request.getAuthStatus().then((res) => {
        if (res === NOTAUTH) {
          Dialog.confirm({
            message: '您还没有实名认证，去认证？',
            cancelButtonText: '暂不处理',
            confirmButtonText: '去认证',
            cancelButtonColor: '#999999',
            confirmButtonColor: '#0081FA'
          }).then(() => {
            // 去认证页面
            this.$router.replace('/auth')
          }).catch(() => {
            // on cancel
          });
        }
      }).catch(() => {

      })
    },
  }
};
</script>

<style lang="scss" scoped>
.tips {
  margin: 18px 21px;
  font-size: 12px;
  color: #666666;
}

.img {
  width: 100%;
  height: 464px
}
</style>
