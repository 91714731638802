/**
 * axiosr二次封装
 */
import axios from 'axios';
import { Toast } from "vant";
import ResCode from '../util/code'

// 创建实例
const instance = axios.create({
	baseURL: '/apis',
	timeout: 4000,
});

// 拦截器 请求拦截
instance.interceptors.request.use(
	(config) => {
		// config.headers = {
		// 	c: 'aDU=',
		// };
		// 部分接口拿到token
		let token = localStorage.getItem('token');
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token
			// config.headers.token = token;
		}
		return config;
	},
	(err) => {
		return new Promise.reject(err);
	}
);

// 拦截器 响应拦截
instance.interceptors.response.use(
	(response) => {
		const res = response.data;
		if (res && res.code !== 0) {
			if (res.code === 10001 || res.code == 10002 || res.code == 10006 || res.code == 10007 || res.code == 10009) {
			  Toast('你已被登出，请重新登录');
			  localStorage.clear();
			  setTimeout(() => {
				location.replace('/')
			  }, 1000);
			}else{
				// 有已记录的错误码就返回错误码 否则统一操作失败
				Toast(ResCode[res.code] || '操作失败');
			}
			return Promise.reject(response);
		  } else {
			return res.data;
		  }
	},
	(err) => {
		Toast(err.message);
		return new Promise.reject(err);
	}
);

// 整体导出
export default instance;
