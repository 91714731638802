<template>
  <div style="height: 100%; background-color: #f7f7f7">
    <div class="line" style="padding-top: 12px">
      <!-- 输入任意文本 -->
      <van-field label="姓名" :value="info.n" readonly input-align="right" />
      <div style="width:100%;background-color: #fff;">
        <div class="seperator"></div>
      </div>
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field type="tel" label="手机号" :value="info.p" readonly input-align="right" />
    </div>
    <div class="line">
      <van-cell title="修改密码" is-link @click="goModifyInfo" />
    </div>
    <div class="line">
      <van-cell title="账户信息" is-link @click="goAccount" />
      <div style="width:100%;background-color: #fff;">
        <div class="seperator"></div>
      </div>
      <van-cell title="实名信息" is-link @click="goAuth" />
    </div>
    <div class="footer">
      <van-button class="btn_confirm" round block @click="logout">退出</van-button>
    </div>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import { Toast } from 'vant';
import request from "../request/api";
const Authrization = '1' //已认证
export default {
  name: "month",
  data() {
    return {
      info: {},
    };
  },
  async mounted() {
    const res = await request.getInfoBank()
    this.info = res;
  },
  methods: {
    goModifyInfo() {
      // this.$router.push('/modify_info')
      this.$router.push({ path: '/modify_info', query: { p: this.info.p } })
    },
    // 去账户信息填写页
    goAccount() {
      this.$router.push({ path: '/account', query: { bank: this.info.bn, bankCardNumber: this.info.bo, jobNumber: this.info.wi } })
    },
    // 去实名认证填写页
    goAuth() {
      request.getAuthStatus().then((res) => {
        if (res == Authrization) {
          Toast('已实名认证')
        } else {
          this.$router.push({ path: '/auth' })
        }
      }).catch(() => {

      })
    },
    // 退出登录
    logout() {
      localStorage.clear()
      this.$router.replace('/login')
    }
  },

  created() {
    ChangePageTitle("个人信息");
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f7f7f7;
}

.line {
  margin-bottom: 12px;

  .van-cell__title {
    span {
      color: #333;
    }
  }
}

.seperator {
  height: 1px;
  background-color: #E6E6E6;
  margin: 0 16px;
}

.footer {
  margin: 62px 40px 0 40px;

  .btn_confirm {
    background: #EDEDED;
    color: #CCCCCC;
    height: 40px;
  }
}

::v-deep input::placeholder {
  color: #999;
}

::v-deep .van-field__label {
  color: #333;
}

::v-deep .van-field__body {
  font-size: 16px;
}

::v-deep .van-field__control {
  color: #333;
}

::v-deep .van-field__control:read-only {
  color: #333;
}

::v-deep .van-cell::after {
  border: none
}

.van-cell--borderless::after,
.van-cell:last-child::after {
  display: initial;
}
</style>
