<template>
  <div style="overflow: hidden;">
    <p class="name_title">{{ $route.query.p }}修改密码</p>
    <van-cell-group :border="false">
      <van-field
        class="input"
        clearable
        :type="pasType3"
        v-model.trim="phone"
        placeholder="请输入原密码"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon
              size="18"
              v-if="pasType3"
              name="closed-eye"
              @click="showEye3"
            />
            <van-icon size="18" v-else name="eye-o" @click="showEye3" />
          </div>
        </template>
      </van-field>
    </van-cell-group>

    <van-cell-group style="margin-top: 30px" :border="false">
      <van-field
        class="input"
        clearable
        v-model.trim="passNew1"
        :type="pasType1"
        placeholder="输入新密码"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon
              size="18"
              v-if="pasType1"
              name="closed-eye"
              @click="showEye1"
            />
            <van-icon size="18" v-else name="eye-o" @click="showEye1" />
          </div>
        </template>
      </van-field>
      <van-field
        class="input"
        clearable
        v-model.trim="passNew2"
        :type="pasType2"
        placeholder="确认新密码"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon
              size="18"
              v-if="pasType2"
              name="closed-eye"
              @click="showEye2"
            />
            <van-icon size="18" v-else name="eye-o" @click="showEye2" />
          </div>
        </template>
      </van-field>
    </van-cell-group>

    <div class="loginBtn">
      <van-button
        class="btn_confirm"
        round
        block
        @click="login"
        :loading="loading"
        >确认修改</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";

export default {
  name: "modify_info",
  data() {
    return {
      pasType1: "password",
      pasType2: "password",
      pasType3: "password",
      name: "",
      phone: "",
      code: "",
      password: "",
      passNew1: "",
      passNew2: "",
      passNew3: "",
      loading: false,
    };
  },
  methods: {
    // 展示隐藏密码
    showEye1() {
      if (this.pasType1) {
        this.pasType1 = "";
      } else {
        this.pasType1 = "password";
      }
    },
    showEye2() {
      if (this.pasType2) {
        this.pasType2 = "";
      } else {
        this.pasType2 = "password";
      }
    },
    showEye3() {
      if (this.pasType3) {
        this.pasType3 = "";
      } else {
        this.pasType3 = "password";
      }
    },

    // 去登录
    async login() {
      if (!this.phone) {
        return Toast("请输入密码");
      }
      if (!this.passNew1 || !this.passNew2) {
        return Toast("请输入密码");
      }
      if (this.passNew1.length < 6 || this.passNew2.length < 6) {
        return Toast("密码长度必须为6-16位");
      }
      if (this.passNew1 !== this.passNew2) {
        return Toast("密码设置不一致，请重新输入");
      }
      this.loading = true;
      const params = {
        p: this.phone,
        d: this.passNew1,
        nd: this.passNew2,
      };
      try {
        await request.editPass(params);
        Toast({
          message: "密码修改成功",
          forbidClick: true,
        });
        setTimeout(() => {
          localStorage.clear();
				  location.replace('/')
        }, 1000);
      } catch {
        this.loading = false;
      }
    },
  },
  created() {
    ChangePageTitle("密码修改");
  },
};
</script>

<style lang="scss" scoped>
.name_title {
  margin: 36px 40px;
  font-size: 18px;
}
.van-cell-group {
  margin: 0 40px 0 40px;
}
.input {
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  padding-left: 0;
}
.van-cell::after {
  border: 0;
}
::v-deep .van-nav-bar {
  .van-icon {
    color: #333;
  }
}
.loginBtn {
  margin: 54px 40px 0 40px;
  .btn_confirm {
    background: linear-gradient(180deg, #3abdff 0%, #1b88ff 100%);
    color: #fff;
    height: 40px;
  }
}
::v-deep input::placeholder{
    color: #999;
}
</style>