// 将request.js 整体导入
import request from './request'

// 按需导出每个请求，也就是按需导出每个api
const apiService = {
    /**
   * 手机号密码登录
   */
    login(data) {
        return request.post('/sale/auth/v1/login', data)
    },

    // 下发验证码
    getCode(data) {
        return request.post('/sale/auth/v1/code', data)
    },


    // 修改密码
    updatePass(data) {
        return request.post('/sale/auth/v1/updatePass', data)
    },

    // 邀请首页-邀请海报
    getPosterImg() {
        return request.get('/sale/app/query/v1/getPosterImg')
    },

    // 认证状态
    getAuthStatus() {
        return request.get('/sale/app/v1/idStatus')
    },

    // 我的页面信息
    getMyInfo() {
        return request.get('/sale/app/query/v1/info')
    },

    // 我的-统计邀请人数
    getMyCensus() {
        return request.get('/docsale/app/query/v1/census')
    },

    // 我的-7天邀请列表
    getMySevenList(st) {
        return request.get('/docsale/app/query/v1/invite?st=' + st)
    },

    // 我的-个人信息
    getInfoBank() {
        return request.get('/sale/app/query/v1/infoBank')
    },

    // 我的-基本信息-编辑银行卡
    editBank(data) {
        return request.post('/sale/app/v1/editBank', data)
    },

    // 我的-基本信息-重置密码
    editPass(data) {
        return request.post('/sale/app/v1/editPass', data)
    },

    //我的-基本信息-身份认证
    identity(data) {
        return request.post('/sale/app/v1/identity', data)
    },

    // 我的-统计邀请人数-总人数列表
    getCensusTotal(data) {
        return request.get('/docsale/app/query/v1/censusTotal', { params: data })
    },

    // 我的-统计邀请人数-月人数列表
    getCensusMm(data) {
        return request.get('/docsale/app/query/v1/censusMm', { params: data })
    },

    // 我的-统计邀请人数-人数列表-详情
    getDoctorInfo(id) {
        return request.get('/docsale/app/query/v1/doctorInfo?i=' + id)
    },

    // 订单统计信息
    getInfoCensus(id) {
        return request.get('/orders/query/v1/getInfoCensus?i=' + id)
    },

    // 问诊统计
    getConsults(id) {
        return request.get('/consults/query/v1/getInfoCensus?i=' + id)
    },

}
export default apiService;
