export default {
        /**** 公共 ****/
        0: '操作成功',
        1: '操作失败',
        2: '请求参数异常',
        10001: '登录令牌过期',
        10002: '刷新令牌过期',
        10003: '用户名或密码错误',
        10004: '用户账户异常',
        10005: '用户未授权',
        10006: '登录令牌验证失败',
        10007: '登录令牌被废除',
        10008: '请求参数缺失或者有误',
        10009: '账号被踢出',
        10010: '原密码有误',
        10011: '登录验证码错误',
        10012: '两次密码设置不一致',
        10013: '验证码已过期',
        10014: '微信登录已过期',
        10015: '微信登录异常',
        10016: '账号已存在',
        10017: '手机号已存在',
        10018: '禁止敏感词作为账号信息',
        10019: '账户不存在',
        10020: '账户信息不允许修改',
        10021: '验证码已发送',

        // 订单
        20001: '未找到对应订单',
        20002: '订单状态异常',
        20003: '患者与医生不存在绑定关系',
        20004: '患者未同意患者知情书',
        20005: '检测项目数量不匹配',
        20006: '存在不匹配检测项目',
        20007: '医生与当前医院不存在绑定关系',
        20008: '检测项目类型异常',
        20009: '已存在样本编码',
        200010: '微信预下单异常',
        200011: '实验室不合法',
        200012: '获取物流二级科室异常',
        200013: '存在单项与套餐项目冲突',
        200014: '异常订单状态异常',
        200015: '退项项目异常',
        200016: '未找到对应异常订单',

        // 医生端
        50001: '请勿重复申请认证',
        50002: '已存在未审核信息',
        50003: '医院科室超过5个',
        50004: '二级科室已存在',
        50005: '患者分组已存在',
        50008: '暂无结算信息，请先添加结算信息',
        50500: '与现有项目名称重复',
        50501: '与现有项目编码重复',
        50502: '与现有套餐名称重复',
        50503: '与现有套餐编码重复',

        60001: '用户被锁定，请联系客服',
        60002: '用户信息异常',
        60003: '验证码错误',
        60004: '已有本人就诊人，重新选择',
        60005: '已有配偶就诊人，重新选择',
        60006: '已有超过4个父母就诊人，重新选择',
        60007: '身份证不合法',
        60008: '当前患者已绑定该医生',
        60009: '当前就诊人为默认不可删除',
        60010: '当前就诊人已存在',
        60011: '无医生信息',
        60012: '当前患者人数超过20个',
        60013: '同一个患者被绑定超过5次',
        60014: '用户信息与身份证信息不符',
        60015: '身份证校验次数超频',

        /**** 公共 ****/
        90001: '账号密码有误，请重新输入',
        90002: '手机号未注册，请先注册',
        90003: '账号异常,请联系客服',
        90004: '两次输入的密码不一致！',
        90005: '该手机号已被绑定,请使用其他手机号！',
        90006: '原密码错误，请重新输入',


        // 身份认证
        60014: '用户信息与身份证信息不符，请重新输入',
        60015: "身份证校验次数超频，请联系客服",
        90007: "身份信息不能重复认证"
}
