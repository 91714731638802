<template>
  <div style="height: 100%; background-color: #f7f7f7">
    <van-search v-model="value" placeholder="搜索内容" @search="onSearch" />
    <div class="time_select" @click="select">
      {{ timeTxt }}<van-icon style="margin-left: 14px" name="arrow-down" />
    </div>

    <van-popup v-model="show" position="bottom" round>
      <van-datetime-picker confirm-button-text="完成" v-model="currentDate" type="year-month" :min-date="minDate"
        :max-date="maxDate" :formatter="formatter" @confirm="confirm" @cancel="show = false" />
    </van-popup>

    <van-list v-model="loading" :finished="finished" :finished-text="finisheedText" @load="onLoad" offset="50">
      <div class="mine">
        <div class="line box">
          <div class="box_view" v-for="item in list" :key="item.i" @click="goInfo(item.i)">
            <img style="width:44px;height: 44px;border-radius: 44px;"
              :src="item.im ? item.im : require('../assets/image/doctor.png')" alt="" />
            <div class="box_mine">
              <div>
                <span class="name">{{ item.n }}</span>
                <span class="option" v-if="item.jt">{{ jobTitle[item.jt].label }}</span>
              </div>
              <p class="type">{{ item.fn }} - {{ item.sn }}</p>
            </div>
            <van-icon style="margin-left: auto" name="arrow" />
          </div>
        </div>
        <div v-if="finished && list.length == 0" class="none_data">
          <img src="../assets/image/quesy.png" alt="" />
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";
import { jobTitle } from '@/util/options'
export default {
  name: "month",
  data() {
    return {
      value: "",
      minDate: new Date(2022, 9),
      maxDate: new Date(),
      currentDate: new Date(),
      starDate: '',
      endDate: '',
      loading: false,
      finished: false, // 还有没有数据
      show: false,
      timeTxt: "",
      list: [],
      finisheedText: "暂无数据",
      jobTitle
    };
  },
  mounted() { },
  methods: {
    goInfo(i) {
      this.$router.push({ path: '/info', query: { i } })
    },
    // 上拉加载
    async onLoad() {
      const pop = this.list[this.list.length - 1];
      try {
        const st = pop ? pop.ct.substr(0, 7) + '-01 00:00:00' : '';
        await this.getCensusMm(st, pop ? pop.ct : '')
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },

    // 请求列表
    async getCensusMm(st, et) {
      const params = {
        st: st || this.starDate,
        et: et || this.endDate,
        n: this.value
      }
      await request.getCensusMm(params).then(res => {
        this.list = st ? this.list.concat(res) : res
        if (res.length == 0) {
          this.finished = true;
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      })
    },

    async onSearch(val) {
      this.list = [];
      this.loading = true;
      this.finished = false;
      this.value = val
      await this.getCensusMm()
      this.loading = false;
    },
    select() {
      this.show = true;
    },
    async confirm(value) {
      const year = new Date(value).getFullYear();
      const month = new Date(value).getMonth() + 1;
      this.starDate = `${year}-${month < 10 ? '0' + month : month}-01 00:00:00`
      this.endDate = `${year}-${month < 10 ? '0' + month : month}-31 23:59:59`
      this.timeTxt = year + "年" + month + "月";
      this.show = false;
      this.list = []
      this.loading = true;
      this.finished = false;
      await this.getCensusMm()
      this.loading = false;

    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
  },
  created() {
    ChangePageTitle("邀请统计");
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    this.starDate = `${year}-${month < 10 ? '0' + month : month}-01 00:00:00`
    this.endDate = `${year}-${month < 10 ? '0' + month : month}-31 23:59:59`
    this.timeTxt = `${year}年${month}月`
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f7f7f7;
}

.van-search__content {
  border-radius: 50px;
  border: 1px solid #e6e6e6;
  background-color: unset;
}

.time_select {
  background: #fff;
  padding: 7px 16px;
  color: #666666;
  font-size: 14px;
}

.mine {
  height: 100%;

  .line {
    margin-bottom: 12px;
  }

  .flex {
    display: flex;

    .van-cell {
      padding-top: 20px;
      padding-bottom: 16px;
    }
  }

  .left_cell {
    font-size: 28px;
    color: #1b88ff;

    >.van-cell__label {
      margin-top: 10px;
    }
  }

  .box {
    background: #fff;
    padding: 0 16px 0 16px;

    >p {
      font-size: 14px;
      padding-bottom: 8px;
    }

    .box_view {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #ebedf0;

      >img {
        height: 44px;
        width: 44px;
        margin-right: 8px;
      }

      .box_mine {
        >div {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
          }
        }

        .name {
          font-size: 16px;
          margin-right: 12px;
          overflow: hidden;
          /*内容超出后隐藏*/
          text-overflow: ellipsis;
          /* 超出内容显示为省略号 */
          white-space: nowrap;
          /* 文本不进行换行 */
          max-width: 85px;
        }

        .option {
          font-size: 20px;
          transform: scale(.5); // 占位置
          transform-origin: 0;
          background: #d6eaff;
          border-radius: 30px;
          color: #1987fe;
          padding: 8px 16px;
        }

        .type {
          font-weight: 400;
          color: #999999;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
  }
}

.none_data {
  text-align: center;
  padding-top: 50px;
}

.van-icon {
  color: #999;
}
</style>
