// 问题分析：
// 主要是因为微信在首次加载页面初始化title后，就再也不监听 document.title的change事件。
// 解决思路：
// 给页面加上一个内容为空的iframe，随后立即删除这个iframe，这时候会刷新title。

export const ChangePageTitle = (titles) => {
    document.setTitle = function (t) {
      document.title = t;
      var i = document.createElement("iframe");
      i.src = "./favicon.ico";
      i.style.display = "none";
      i.onload = function () {
        setTimeout(function () {
          i.remove();
        }, 9);
      };
      document.body.appendChild(i);
    };
    setTimeout(function () {
      document.setTitle(titles);
    }, 1);
}