export const jobTitle = [
    {
        label: '主任医师',
        val: 0
    },
    {
        label: '副主任医师',
        val: 1
    },
    {
        label: '主治医师',
        val: 2
    },
    {
        label: '住院医师',
        val: 3
    }
]