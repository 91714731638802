<template>
    <div style="height: 100%; background-color: #f7f7f7">
        <div class="line" style="padding-top: 12px">
            <!-- 输入任意文本 -->
            <van-field v-model="account.jobNumber" label="工号" input-align="right" maxlength="15" placeholder="请输入" />
        </div>
        <div class="line">
            <!-- 输入任意文本 -->
            <van-field v-model="account.bank" label="开户行名称" input-align="right" maxlength="15" placeholder="请输入" />
            <div style="width:100%;background-color: #fff;">
                <div class="seperator"></div>
            </div>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="account.bankCardNumber" type="tel" label="银行卡号" input-align="right" maxlength="19"
                placeholder="请输入" />
        </div>
        <div class="footer">
            <van-button class="btn_confirm" round block @click="confirm">确认</van-button>
        </div>
    </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";
import { Toast } from 'vant';
export default {
    data() {
        return {
            account: {}
        }
    },
    created() {
        ChangePageTitle("账户信息");
    },
    mounted() {
        this.account = this.$route.query
    },
    computed: {
    },
    methods: {
        async confirm() {
            if (this.account.bankCardNumber && this.account.bankCardNumber.length < 12) {
                return Toast('请输入正确的银行卡号')
            }
            const params = {
                bn: this.account.bank,
                bo: this.account.bankCardNumber,
                wi: this.account.jobNumber
            }
            await request.editBank(params)
            Toast('保存成功')
        },
    },
}
</script>
<style lang="scss" scoped>
body {
    background-color: #f7f7f7;
}

.line {
    margin-bottom: 12px;

    .van-cell__title {
        span {
            color: #333;
        }
    }
}

.seperator {
    height: 1px;
    background-color: #E6E6E6;
    margin: 0 16px;
}

.footer {
    margin: 182px 40px 0 40px;

    .btn_confirm {
        background: linear-gradient(180deg, #3abdff 0%, #1b88ff 100%);
        color: #fff;
        height: 40px;
    }
}

::v-deep input::placeholder {
    color: #999;
}

::v-deep .van-field__label {
    color: #333;
}

::v-deep .van-field__body {
    font-size: 16px;
}

::v-deep .van-field__control {
    color: #333;
}

::v-deep .van-field__control:read-only {
    color: #333;
}

::v-deep .van-cell::after {
    border: none
}

.van-cell--borderless::after,
.van-cell:last-child::after {
    display: initial;
}
</style>
