<template>
  <div style="overflow: hidden;">
    <van-cell-group :border="false">
      <van-field
        class="input"
        clearable
        v-model.trim="phone"
        placeholder="输入手机号"
        maxlength="11"
      />
      <van-field
        class="input"
        clearable
        v-model.trim="code"
        type="number"
        placeholder="输入验证码"
        maxlength="6"
      >
        <template #button>
          <div
            v-if="countDownNum == 60 || countDownNum == -1"
            @click="getCode"
            style="margin-left: 15px; color: #5c88fe"
          >
            获取验证码
          </div>
          <div style="color: #5c88fe" v-else>{{ countDownNum }}s后重新获取</div>
        </template>
      </van-field>
    </van-cell-group>

    <van-cell-group style="margin-top: 30px" :border="false">
      <van-field
        class="input"
        clearable
        v-model.trim="passNew1"
        :type="pasType1"
        placeholder="输入新密码"
        @input="input"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon
              size="18"
              v-if="pasType1"
              name="closed-eye"
              @click="showEye1"
            />
            <van-icon size="18" v-else name="eye-o" @click="showEye1" />
          </div>
        </template>
      </van-field>
      <van-field
        class="input"
        clearable
        v-model.trim="passNew2"
        :type="pasType2"
        placeholder="确认新密码"
        @input="input"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon
              size="18"
              v-if="pasType2"
              name="closed-eye"
              @click="showEye2"
            />
            <van-icon size="18" v-else name="eye-o" @click="showEye2" />
          </div>
        </template>
      </van-field>
    </van-cell-group>

    <div class="loginBtn">
      <van-button class="btn_confirm" round block @click="confirm"
        >确认修改</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";

export default {
  name: "modify",
  data() {
    return {
      pasType1: "password",
      pasType2: "password",
      name: "",
      phone: "",
      code: "",
      password: "",
      adopt: false,
      passNew1: "",
      passNew2: "",
      countDownNum: 60, // 倒计时初始值
    };
  },
  methods: {
    // 展示隐藏密码
    showEye1() {
      if (this.pasType1) {
        this.pasType1 = "";
      } else {
        this.pasType1 = "password";
      }
    },
    showEye2() {
      if (this.pasType2) {
        this.pasType2 = "";
      } else {
        this.pasType2 = "password";
      }
    },

    // 监听输入事件
    input(e) {
      if (this.name && this.password) {
        this.adopt = true;
      } else {
        this.adopt = false;
      }
    },

    // 发送手机验证码
    getCode() {
      if (!!this.phone) {
        if (!!/^1[3456789]\d{9}$/.test(this.phone)) {
          const params = {
            p: this.phone,
          };
          request.getCode(params).then((res) => {
            console.log(res);
            Toast("发送成功");
            this.countDown();
          });
        } else {
          Toast("请输入正确的手机号");
        }
      } else {
        Toast("请输入手机号");
      }
    },

    /**
     * 验证码倒计时
     */
    countDown() {
      var _this = this;
      var countDownNum = _this.countDownNum; // 获取倒计时初始值
      var timer = setInterval(function () {
        countDownNum -= 1;
        _this.countDownNum = countDownNum;

        if (countDownNum <= -1) {
          clearInterval(timer);
          // 取消置顶的setInterval函数将要执行的代码
          _this.countDownNum = 60;
        }
      }, 1000);
    },

    // 修改密码
    confirm() {
      if (!this.phone) {
        return Toast("请输入手机号");
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        return Toast("请输入正确手机号");
      }
      if (!this.code || this.code.length !== 6) {
        return Toast("请输入正确验证码");
      }
      if (!this.passNew1 || !this.passNew2) {
        return Toast("请输入密码");
      }
      if (this.passNew1 !== this.passNew2) {
        return Toast("密码设置不一致，请重新输入");
      }

      const params = {
        p: this.phone,
        c: this.code,
        d: this.passNew1,
        nd: this.passNew2,
      };
      request.updatePass(params).then((res) => {
        console.log(res);
          Toast({
            message: '修改成功',
            forbidClick: true,
          });
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000);
      });
    },
  },
  created() {
    ChangePageTitle("密码修改");
  },
};
</script>

<style lang="scss" scoped>
.van-cell-group {
  margin: 63px 40px 0 40px;
}
.input {
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  padding-left: 0;
}
.van-cell::after {
  border: 0;
}
::v-deep .van-nav-bar {
  .van-icon {
    color: #333;
  }
}
.loginBtn {
  margin: 54px 40px 0 40px;
  .btn_confirm {
    background: linear-gradient(180deg, #3abdff 0%, #1b88ff 100%);
    color: #fff;
    height: 40px;
  }
}
::v-deep input::placeholder{
    color: #999;
}
</style>