<template>
    <div style="height: 100%; background-color: #f7f7f7;overflow: hidden;">
        <div class="line">
            <!-- 输入任意文本 -->
            <van-field v-model.trim="account.username" label="姓名" input-align="right" maxlength="10" placeholder="请输入" />
            <div style="width:100%;background-color: #fff;">
                <div class="separator"></div>
            </div>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model.trim="account.idCard" label="身份证号" input-align="right" maxlength="18"
                placeholder="请输入" />
        </div>
        <div class="footer">
            <van-button class="btn_confirm" round block @click="confirm">确认</van-button>
        </div>
    </div>
</template>
<script>
import { ChangePageTitle } from "@/util/title";
import { Toast } from 'vant';
import request from "../request/api";
export default {
    data() {
        return {
            account: {
                username: '',
                idCard: ''
            }
        }
    },
    created() {
        ChangePageTitle("实名信息");
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        checkData() {
            if (this.account.username === '') {
                Toast('请填写您的姓名')
                return false
            } else if (this.account.idCard === '') {
                Toast('请填写身份证号')
                return false
            } else if (this.account.idCard.length !== 18) {
                Toast('身份证号格式错误')
                return false
            } else {
                return true
            }
        },
        confirm() {
            if (this.checkData()) {
                let params = {
                    sn: this.account.username,
                    si: this.account.idCard
                }
                request.identity(params).then(() => {
                    Toast('认证成功')
                    this.$router.go(-1);
                }).catch(() => {
                })
            }
        }
    },
}
</script>
<style lang="scss" scoped>
body {
    background-color: #f7f7f7;
}

.line {
    margin-top: 12px;

    .van-cell__title {
        span {
            color: #333;
        }
    }
}

.separator {
    height: 1px;
    background-color: #E6E6E6;
    margin: 0 16px;
}

.footer {
    margin: 248px 40px 0 40px;

    .btn_confirm {
        background: linear-gradient(180deg, #3abdff 0%, #1b88ff 100%);
        color: #fff;
        height: 40px;
    }
}

::v-deep input::placeholder {
    color: #999;
}

::v-deep .van-field__label {
    color: #333;
}

::v-deep .van-field__body {
    font-size: 16px;
}

::v-deep .van-field__control {
    color: #333;
}

::v-deep .van-field__control:read-only {
    color: #333;
}

::v-deep .van-field__label::after {
    content: "*";
    color: #1b88ff;
    display: inline-block;
    vertical-align: middle;
    line-height: 24px;
    font-size: 14px;
    margin-left: 9px;
}

::v-deep .van-cell::after {
    border: none
}

.van-cell--borderless::after,
.van-cell:last-child::after {
    display: initial;
}
</style>
